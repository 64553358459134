import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/tlarson/dev/home/familyhistories/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>How can you share documents?</h3>
    <p>
  So, you've got a number of files you want to share, and you want others to get new versions automatically.
  Here are ways to set up each folder.
  <br />
  <br />
  Do you want to share it with the entire world?
  <ul>

  <li>"Yes, I want to share it with the entire world." (BTW, I assume you do not want to have everyone else in the world change information in those files.  If you want that, let's talk, because I'm very curious what you're doing!)

    <br />
    Here are some options:

      <ul>

      <li><a href="https://vuze.com">Vuze</a>, a program build on the standard BitTorrent protocol,
      though a little complicated to set up.

        <ol>

        <li> Add a share: go to File, Share, and Folder Contents and choose the folder that you'd like to
          share.  </li>

        <li>Then make a category for it: go to Tools and My Shares, then right-click on the folder and
        "Assign Category" and "Add Category" and give the name you'd like.  </li>

        <li> Then create the feed: go to "Library" on the left, right-click on the category name (which
          shows in the upper-right, above the files) and enable "Create local RSS feed".  </li>

        <li>Then find your RSS file to publish: go to "Tools", "Options", "Local RSS etc", and click on
        "Click to view the local home page", click on "categories", and there you will find links to the
        RS files to share with the world.  <a href="https://thomas.tolmanfamily.org/data.php#subscribe">Here</a> are some examples of some
        published feeds.  </li>

        <li>FYI: Alternatively, you can use the <a href="https://wiki.vuze.com/w/Distributed_Database_Trusted_Feed">DDB Feed plugin</a> to create your
        feed, which gives you a "publish.xml" file that makes it easy for others to subscribe if they also
        install the DDB Feed plugin.)  </li>

        </ol>

      </li>

      <li><a href="https://nextcloud.com">NextCloud</a>, a server model, hosted by yourself or them.

      </li>
      </ul>

    Tools that don't work:
      <ul>

      <li>Dropbox: you can publish a public folder for the whole world, but if you want others to get the
      files automatically then you have to explicitly invite them individually.</li>

      <li>Syncthing: the protocol isn't designed for public shares, and the workflow for adding shares doesn't support it.</li>

      </ul>

  </li>

  <li>"No, I want to share it with select people (not the entire world)."

      <br />
      Then, do you want to keep your own copy separate from the files that other people change?

      <ul>
      <li>"Yes, I want to keep my own copy."

          <ul>
          <li>Use a file-sharing tool that allows editing, then coordinate with <a href="https://github.com/trentlarson/P2P-Docs">P2P-Docs</a>.


            <ol>
            <li>Choose a sync tool:

              <ul>

              <li><a href="https://syncthing.net">SyncThing</a>
                <br />
                SyncThing is a local app that allows synchronization directly between computers.
              </li>

              <li><a href="https://nextcloud.com">NextCloud</a>
                <br />
                NextCloud has a server and client, and it's possible to sync public folders. It's easy to begin with their servers, but it takes more expertise to run your own.
              </li>

              <li><a href="https://vuze.com">Vuze</a>

                <br />
                Vuze is a bittorrent app, so allows the broad distribution of files, especially large ones.

                <ol type="a">
                <li>Install <a href="https://vuze.com">Vuze</a></li>

                <li>Go to Tools and Options, then Plugins and Friends and click on Enabled.  Then go to File
                and Restart Vuze.</li>

                <li>Set up your folder for outgoing files: Create a folder.  Go to File, Share, and Folder
                Contents (Recursive), and select that folder.  Then go to Tools and My Shares, right-click on
                that folder, and choose Assign Category and Add Category with any name you like.  Then, after
                you add your friends, go to Tools, Plugins, and Friends and right-click on them and choose
                Categories and "Enable subscriptions with friend(s)" and enter the name of the category you
                want to give them.</li>

                <li>Set up your incoming folder:
                  <ol>

                  <li> Add ability to save downloads to different directories: Go to Tools, Plugins,
                    Installation Wizard.  Click Next (for the list from sourceforge.net), choose the Save
                    Path plugin, then click Next and Finish so that it completes the install.  Restart
                    Vuze.  Then go to Tools and Options, then expand the Plugins list so that you see Save
                    Path below it, and click on it and choose Category as the setting for new downloads.
                    </li>

                  <li> Get the friend key from your friend.  Go to Tools, Plugins, then Friends.  A screen
                    will show where you can enter your friend's key, then Add.  Then you can right-click
                    on the user, go to Categories, then Subscribe, and choose the categories to pull.  It
                    will show up on the left-hand-side under SUBSCRIPTIONS; click on it, and make sure
                    Auto-download is enabled.  Right-click on the subscription and choose Assign Category
                    and choose the category you'd like to name this feed.  The files will be downloaded
                    into a subdirectory with that name.  </li>

                </ol>
                </li>

                </ol>
              </li>
              <li><a href="https://dropbox.com">Dropbox</a> (The free version has a 2 GB limit.)
                <ol type="a">
                <li>Install <a href="https://dropbox.com">Dropbox</a></li>

                <li>Create a folder inside the Dropbox folder for your outgoing files.  Don't put anything in
                it.  (If you want a separate folder for incoming changes, then make it, too.)</li>

                <li>Share that folder for outgoing files.  You can do it online by logging in to <a href="https://dropbox.com">dropbox.com</a>; you can also click on the Dropbox service icon and "Open
                Dropbox Folder", then find the folder you want to share and right-click on it and select
                "Share this folder...".</li>

                <li>If you want a separate folder for incoming files, create another one
                and share the same way.</li>

                </ol>
              </li>
              </ul>
            </li>
            <li>Choose a tool to manage the changes from others.
              <ul>
              <li><a href="https://rsync.samba.org/">rsync</a> is a powerful command-line utility.
              </li>
              <li><a href="https://github.com/trentlarson/P2P-Docs">P2P-Docs</a> helps review all changes, but there's only a Windows distribution and it's built on an old framework so it's being replaced by <a href="https://github.com/trentlarson/distrinet">Distrinet</a>.
                <ol>
                <li>Install it.</li>

                <li>Configure a library where the incoming folder (and maybe the outgoing folder) are the folder(s) you created in the sync tool, and the home folder is the one containing your copy of the files.</li>

                <li>After setup, you can see what other people have changed, or see what you
                have changed and push your new stuff to everyone invited to your folder.</li>

                </ol>
              </li>
              <li><a href="https://github.com/trentlarson/distrinet">Distrinet</a> is soon to be an option, but it's not ready yet.
              </li>
              </ul>
            </li>
            </ol>
          </li>
          </ul>
      </li>

      <li>"No, I don't want to keep my own copy separate, safe from other people's changes."

        <br />
        You don't need anything complicated: use  <a href="https://Dropbox.com">Dropbox</a> (a popular service) or <a href="https://spideroak.com">SpiderOak</a> (one where all files are secure... even the SpiderOak company cannot see the file contents).

      </li>
      </ul>

  </li>
  </ul>
    </p>
    <p>
  The Thomas Tolman Family Organization publishes it's genealogy data publicly,
  <a href="https://thomas.tolmanfamily.org/data.php#subscribe">&nbsp;and you can subscribe to data updates using P2P tools.</a>
    </p>
    <p>
  My latest software for searching and cooperating with your P2P (or
  other file-sharing) program
  <a href="https://github.com/trentlarson/P2P-Docs/blob/master/README.md">
    &nbsp;can be downloaded from here
  </a>
  .  (The source code
  <a href="https://github.com/trentlarson/P2P-Docs">
    &nbsp;can be retrieved here
  </a>
  .)
    </p>
    <p>
  If you want to test out any of this back-and-forth sharing (or any other approaches), drop me a line. If
  you've got any other ideas, drop me a line.  There are a bunch of other people working on related issues, so
  I'm sure you'll find a group with similar interests.  This is getting fun!
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      